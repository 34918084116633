@import "../../styles/variables";

.heroBreakpoint * {
    box-sizing: border-box;
}

.heroBreakpoint {
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: relative;
    margin: 20px auto;

    &[data-hero-placement="full"],
    &[data-hero-placement="background-full"] {
        width: 100%;
    }
    &[data-hero-placement="container"] {
        @include container;
    }
    &[data-vertical-margin="zero"] {
        margin-top: 0;
        margin-bottom: 0;
    }
    &[data-background-color="color1"] {
        background-color: var(--flexihero-color1);

        @media ($dark-mode) {
            background-color: var(--flexihero-color1-dark-mode);
        }
    }
    &[data-background-color="color2"] {
        background-color: var(--flexihero-color2);

        @media ($dark-mode) {
            background-color: var(--flexihero-color2-dark-mode);
        }
    }
    &[data-background-color="color3"] {
        background-color: var(--flexihero-color3);

        @media ($dark-mode) {
            background-color: var(--flexihero-color3-dark-mode);
        }
    }
    &[data-background-color="color4"] {
        background-color: var(--flexihero-color4);

        @media ($dark-mode) {
            background-color: var(--flexihero-color4-dark-mode);
        }
    }
    &[data-background-color="color5"] {
        background-color: var(--flexihero-color5);

        @media ($dark-mode) {
            background-color: var(--flexihero-color5-dark-mode);
        }
    }
    &[data-background-color="transparent"] {
        background-color: transparent;
    }

    &[data-background-media-aspect-ratio="true"] {
        display: block;
    }
}

.allContent {
    display: flex;
    width: 100%;

    [data-hero-placement="background-full"]:not(
            [data-background-media-aspect-ratio="true"]
        )
        & {
        @include container;
    }

    [data-background-media-aspect-ratio="true"] & {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &[data-hero-direction="row"] {
        flex-direction: row;
    }
    &[data-hero-direction="column"] {
        flex-direction: column;
    }
    &[data-hero-direction="row"][data-reversed="true"] {
        flex-direction: row-reverse;
    }
    &[data-hero-direction="column"][data-reversed="true"] {
        flex-direction: column-reverse;
    }
    &[data-content-placement="left/top"] {
        justify-content: flex-start;
    }
    &[data-content-placement="middle"] {
        justify-content: center;
    }
    &[data-content-placement="right/bottom"] {
        justify-content: flex-end;
    }
    &[data-axis="left/top"] {
        align-items: flex-start;
    }
    &[data-axis="middle"] {
        align-items: center;
    }
    &[data-axis="right/bottom"] {
        align-items: flex-end;
    }
    &[data-axis="stretch"] {
        align-items: stretch;
    }
    &[data-gap="true"] {
        gap: var(--flexihero-padding);
    }
    &[data-pad="true"] {
        padding: var(--flexihero-padding);
    }
}
