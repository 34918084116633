@import "../../styles/variables";

.content {
    display: flex;
    justify-content: center;
    flex-direction: column;

    &[data-background*="color"],
    [data-background*="color"] {
        z-index: 1;
    }

    &[data-background="color1"],
    [data-background="color1"] {
        background-color: var(--flexihero-color1);

        @media ($dark-mode) {
            background-color: var(--flexihero-color1-dark-mode);
        }
    }

    &[data-background="color2"],
    [data-background="color2"] {
        background-color: var(--flexihero-color2);

        @media ($dark-mode) {
            background-color: var(--flexihero-color2-dark-mode);
        }
    }

    &[data-background="color3"],
    [data-background="color3"] {
        background-color: var(--flexihero-color3);

        @media ($dark-mode) {
            background-color: var(--flexihero-color3-dark-mode);
        }
    }

    &[data-background="color4"],
    [data-background="color4"] {
        background-color: var(--flexihero-color4);

        @media ($dark-mode) {
            background-color: var(--flexihero-color4-dark-mode);
        }
    }

    &[data-background="color5"],
    [data-background="color5"] {
        background-color: var(--flexihero-color5);

        @media ($dark-mode) {
            background-color: var(--flexihero-color5-dark-mode);
        }
    }

    &[data-background="transparent"],
    [data-background="transparent"] {
        background-color: transparent;
    }

    &[data-width="third"] {
        width: 33%;
    }
    &[data-width="half"] {
        width: 50%;
    }
    &[data-width="two-thirds"] {
        width: 67%;
    }
    &[data-width="full"],
    [data-hero-placement="background-full"] & {
        width: 100%;
    }
    &[data-text-color="light"] {
        color: var(--flexihero-text-light);

        @media ($dark-mode) {
            color: var(--flexihero-text-dark);
        }
    }
    &[data-text-color="inherit"],
    &[data-text-color="dark"] {
        color: var(--flexihero-text-dark);

        @media ($dark-mode) {
            color: var(--flexihero-text-light);
        }
    }

    [data-background-media-aspect-ratio="true"][data-hero-placement="background-full"]
        & {
        @include container;
    }

    [data-hero-placement="background-full"]
        [data-content-placement="left/top"]
        & {
        align-items: flex-start;
    }
    [data-hero-placement="background-full"]
        [data-content-placement="middle"]
        & {
        align-items: center;
    }
    [data-hero-placement="background-full"]
        [data-content-placement="right/bottom"]
        & {
        align-items: flex-end;
    }
}

.inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 1;

    &[data-alignment="left"] {
        align-items: flex-start;
        text-align: left;
    }
    &[data-alignment="right"] {
        align-items: flex-end;
        text-align: right;
    }
    &[data-alignment="center"] {
        align-items: center;
        text-align: center;
    }
    &[data-alignment="justify"] {
        align-items: stretch;
        text-align: justify;
    }

    [data-hero-placement="background-full"] [data-width="third"] & {
        width: 33%;
    }
    [data-hero-placement="background-full"] [data-width="half"] & {
        width: 50%;
    }
    [data-hero-placement="background-full"] [data-width="two-thirds"] & {
        width: 67%;
    }
    [data-hero-placement="background-full"] [data-width="full"] & {
        width: 100%;
    }
}

.eyebrow {
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 0.8em;
}

.title {
    margin: 0 0 15px;
    background-color: transparent;
    color: currentColor;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.03em;
}

.body {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    background-color: transparent;
}

.button {
    padding: 10px 20px;
    font-size: 0.8em;
    text-transform: uppercase;
    border: 1px solid currentColor;
}
