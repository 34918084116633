@import "../../styles/variables";

.controlWrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;

    &.controlsInCenter {
        align-items: center;
        justify-content: center;
    }

    &.controlsInCorner {
        align-items: flex-end;
        justify-content: flex-start;
    }

    &.visibleOnHover:not(.forceShowPlay) {
        opacity: 0;
        transition: opacity 0.2s;

        &:hover {
            opacity: 1;
        }
    }
}

.control {
    $svg-width: 23px;

    &:hover svg {
        .controlsInCenter & {
            background-color: $white;
            fill: $pewter3;
        }
    }

    svg {
        background-color: transparent;
        fill: $white;
        transition:
            background-color 0.2s,
            fill 0.2s;

        .controlsInCenter & {
            $padding-y: 16px;
            $padding-x: 32px;
            width: $padding-x + $svg-width + $padding-x;
            height: $padding-y + $svg-width + $padding-y;
            padding: $padding-y $padding-x;
            border: 1px solid $white;
            border-radius: 60px;
        }

        .controlsInCorner & {
            $padding-y: 12px;
            $padding-x: 12px;
            width: $padding-x + $svg-width + $padding-x;
            height: $padding-y + $svg-width + $padding-y;
            padding: $padding-y $padding-x;
        }
    }
}
