@use "sass:math";
@import "../../styles/variables";

.content > * {
    margin: 100px;

    @media ($below-laptop) {
        margin: 60px;
    }

    @media ($below-tablet) {
        margin: 65px 30px;
    }

    @media ($below-mobile) {
        margin: 30px 15px;
    }
}

.slimHeroContent {
    composes: grid from "../../styles/layout.module.scss";
    composes: paddedContainer from "../../styles/layout.module.scss";
    display: grid;

    > * {
        margin-top: 70px;
        margin-bottom: 70px;

        @media ($below-tablet) {
            margin: 60px 30px;
        }

        @media ($below-mobile) {
            margin: 30px 15px;
        }
    }
}

.hasOverlay {
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($black, 0.3);
    }
}

.borderBottom {
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid $pewter1;
}

.homeHeroMobile {
    padding: 30px 15px 50px;
}

.homeHeroTablet {
    --container-min-margin: 60px !important;
}

$scrollbar-adjustment: 20px;

[data-reversed="true"] .notchWrapper,
[data-reversed="false"] .notchWrapper {
    width: calc(
        50% +
            unquote(
                "min(calc((100vw - #{$container-width} - #{$scrollbar-adjustment}) / 2), 100px)"
            )
    );

    @media ($below-laptop) {
        width: calc(50% + 60px);
    }

    @media ($below-tablet) {
        width: calc(50% + 30px);
    }

    @media ($below-mobile) {
        width: 100%;
    }
}

.notchHero[data-hero-placement="container"] {
    @media ($below-mobile) {
        margin-left: 0;
        margin-right: 0;
    }
}

.notchWrapper {
    @media ($above-mobile) {
        min-height: 500px;
    }
}

[data-reversed="true"] .notchWrapper {
    margin-right: unquote(
        "max(calc((100vw - #{$container-width} - #{$scrollbar-adjustment}) / 2 * -1), -100px)"
    );

    @media ($below-laptop) {
        margin-right: -60px;
    }

    @media ($below-tablet) {
        margin-right: -30px;
    }

    @media ($below-mobile) {
        margin-right: 0;
    }
}

[data-reversed="false"] .notchWrapper {
    margin-left: unquote(
        "max(calc((100vw - #{$container-width} - #{$scrollbar-adjustment}) / 2 * -1), -100px)"
    );

    @media ($below-laptop) {
        width: calc(50% + 60px);
        margin-left: -60px;
    }

    @media ($below-tablet) {
        width: calc(50% + 30px);
        margin-left: -30px;
    }

    @media ($below-mobile) {
        margin-right: 0;
    }
}

.notchMediaWrapper {
    max-height: calc(100% - 100px);

    @media ($above-mobile) {
        &[data-stretched="true"] {
            height: 100%;
            align-self: auto;
        }

        min-height: 400px;
    }
}

.verticalMedia {
    > * {
        object-fit: contain;
    }

    @media ($below-mobile) {
        max-width: 300px;
        margin: 0 auto;
    }
}

.verticalMediaOuter[data-hero-placement="container"] {
    @media ($above-tablet) {
        // Flexihero isn't on our grid, but this is a way to simulate trimming
        // two columns off of each side
        --container-width: calc(
            #{$container-width} - (#{$container-width} / 12 * 4)
        );
    }
}

.verticalMediaContent > * {
    margin: 0 2.5rem;

    @media ($below-mobile) {
        margin: 1rem 0;
    }

    [data-content-placement="left/top"] & {
        margin-left: 0;
    }

    [data-content-placement="right/bottom"] & {
        margin-right: 0;
    }
}
