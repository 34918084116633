@import "../../styles/variables";

.media {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    overflow: hidden;

    &[data-stacked="true"] {
        width: 100%;
    }

    &[data-stretched="true"] {
        align-self: stretch;

        > * {
            height: 100%;
        }
    }

    &[data-background="true"] {
        [data-background-media-aspect-ratio="false"] & {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            > * {
                height: 100%;
            }
        }
    }

    > * {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.mediaInlineContainer {
    position: relative;
    width: 100%;
    height: 0;

    img,
    video,
    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        object-fit: cover;
    }
}
